const elementUtils = require('../../utils/elementUtils');
const numberUtils = require('../../utils/numberUtils');

const BENEFICIARY_IDS_ADDED = [];
let NB_BENEFICIARIES_ADDED = 0;
let ON_ERROR = false;

const BENEFICIARIES_WRAPPER_ELEMENT_ID = 'beneficiaries-wrapper';
const BENEFICIARIES_LIST_ELEMENT_ID = 'beneficiaries';
const TAX_AMOUNT_INPUT_ID = 'amount-school';
const BENEFICIARIES_SELECT_INPUT_ID = 'beneficiaries-selection';
const SUBFORM_SUBMIT_INPUT_ID = 'pass-beneficiaries-step';
const BENEFICIARY_LABEL_CLASS_NAME = 'beneficiary-label';
const ADD_BENEFICIARY_BUTTON_ID = 'add-beneficiary';
const TAX_AMOUNT_SPLITTING_ELEMENT_ID = 'diplome_ou_site_1';
const DELETE_BUTTON_CLASS_NAME = 'delete-button';

/**
 * Display error message.
 */
function displayErrorMessage(message) {
    const errorHtml = '<p id="splitting-error-message" class="global-error"></p>';
    const errorElement = elementUtils.createElementFromHTML(errorHtml);
    errorElement.innerText = '* ' + message;

    document.getElementById(BENEFICIARIES_WRAPPER_ELEMENT_ID).appendChild(errorElement);
    ON_ERROR = true;
}

/**
 * Remove error message.
 */
function removeErrorMessage() {
    if(ON_ERROR) {
        document.getElementById(BENEFICIARIES_WRAPPER_ELEMENT_ID).lastChild.remove();
    }

    ON_ERROR = false;
}

/**
 * Get splitted amounts total.
 */
function getSplittedAmountsTotal() {
    let totalAmount = 0;

    document.getElementById(BENEFICIARIES_LIST_ELEMENT_ID).querySelectorAll('input[type="text"]').forEach(function(element) {
        totalAmount = totalAmount + numberUtils.convertToFloat(element.value);
    });

    return totalAmount;
}

/**
 * Add beneficiary fields.
 */
function addBeneficiaryFields(beneficiaryId, beneficiaryLabel, manageCompanyInformationFieldsVisibility) {
    const htmlFieldsTemplate =
        '<p class="form-grid-70-left form-align" data-value-type="€">' +
        '<label for="amount-school-or-diploma-3">' +
        '<button class="delete-button" aria-labelledby="Supprimer le champ pour cette école/ce diplôme">' +
        '<span aria-hidden="true">×</span>' +
        '</button>' +
        '<span class="'+ BENEFICIARY_LABEL_CLASS_NAME + '"> __beneficiary_label__</span>' +
        '<abbr class="symbol-required">*</abbr>' +
        '</label>' +
        '<input id="id-beneficiary-__beneficiary_nb__" name="id-beneficiary-__beneficiary_nb__" type="hidden" value="__beneficiary_id__"/>' +
        '<input id="amount-beneficiary-__beneficiary_nb__" name="amount-beneficiary-__beneficiary_nb__" class="beneficiary-amount" type="text" placeholder="1000,00" pattern="\\d*([,\\.]\\d{1,2})?" value="" min="1" data-value-missing="Veuillez saisir le montant à affecter" data-pattern-mismatch="Le montant est à limité à 2 décimales." ' +
        'required>' +
        '</p>';

    let widget = htmlFieldsTemplate.replace(/__beneficiary_label__/g, beneficiaryLabel);
    widget = widget.replace(/__beneficiary_id__/g, beneficiaryId);
    widget = widget.replace(/__beneficiary_nb__/g, NB_BENEFICIARIES_ADDED);
    const widgetElement = elementUtils.createElementFromHTML(widget);

    // add event listener on button to delete beneficiary
    widgetElement.querySelector('button').addEventListener('click', function(e) {
        e.preventDefault();

        const target = e.target;
        let button = target;
        if(target.nodeName === "SPAN") {
            button = target.parentElement;
        }

        removeBeneficiaryFields(button);
        handleAmountSplittingConsistence();
        manageCompanyInformationFieldsVisibility();
    });

    // add event listener on every text inputs to check amount splitting consistence
    const amountInputElement = widgetElement.querySelector('input[type="text"]');
    amountInputElement.addEventListener('input', function() {
        handleAmountSplittingConsistence();
    });

    // set amount input value based on left amount to split
    const taxAmount = numberUtils.convertToFloat(document.getElementById(TAX_AMOUNT_INPUT_ID).value);
    const currentTotal = getSplittedAmountsTotal();
    const value = (taxAmount - currentTotal > 0) ? taxAmount - currentTotal : 0;
    amountInputElement.value = (value % 1 != 0) ? value.toFixed(2) : value;

    const wrapperElement = document.getElementById(BENEFICIARIES_LIST_ELEMENT_ID);
    wrapperElement.appendChild(widgetElement);

    // remove added beneficiary from select
    document.getElementById(BENEFICIARIES_SELECT_INPUT_ID).querySelectorAll('option').forEach(function(element) {
        if(element.value === beneficiaryId) {
            element.remove();
        }
    });

    BENEFICIARY_IDS_ADDED.push(beneficiaryId);
    NB_BENEFICIARIES_ADDED++;

    handleAmountSplittingConsistence();
}

/**
 * Remove beneficiary fields.
 *
 * @param buttonElement
 */
function removeBeneficiaryFields(buttonElement) {
    const parentElem = buttonElement.parentElement;
    const beneficiaryId = parentElem.nextSibling.value;
    const beneficiaryLabel = parentElem.querySelector('.' + BENEFICIARY_LABEL_CLASS_NAME).innerText;

    const selectOption = document.createElement('option');
    selectOption.value = beneficiaryId;
    selectOption.innerText = beneficiaryLabel;
    document.getElementById(BENEFICIARIES_SELECT_INPUT_ID).appendChild(selectOption);

    BENEFICIARY_IDS_ADDED.splice(BENEFICIARY_IDS_ADDED.indexOf(beneficiaryId), 1);
    NB_BENEFICIARIES_ADDED--;

    buttonElement.parentElement.parentElement.remove();
}

/**
 * Handle amount splitting consistence.
 */
function handleAmountSplittingConsistence(amountToSplitElement = null) {
    const amountToSplitElem = amountToSplitElement || document.getElementById(TAX_AMOUNT_INPUT_ID);
    let totalAmount = 0;

    removeErrorMessage();

    let amountToSplit = amountToSplitElem.value;
    if(amountToSplit === "") {
        displayErrorMessage("Le montant à affecter n'a pas été défini.");

        return;
    }
    amountToSplit = numberUtils.convertToFloat(amountToSplit).toFixed(2);

    let nbBeneficiaries = 0;
    document.getElementById(BENEFICIARIES_LIST_ELEMENT_ID).querySelectorAll('input[type="text"]').forEach(function(element) {
        totalAmount = totalAmount + numberUtils.convertToFloat(element.value);
        nbBeneficiaries++;
    });
    totalAmount = totalAmount.toFixed(2);

    if(!nbBeneficiaries) {
        displayErrorMessage('Veuillez répartir le montant à affecter.');

        return;
    }

    if(totalAmount < amountToSplit) {
        displayErrorMessage("Le total du montant réparti n'est pas égal au montant à affecter.");

        return;
    }

    if(totalAmount > amountToSplit) {
        displayErrorMessage("Le total du montant réparti est supérieur au montant à affecter.");
    }
}

/**
 * Is on error.
 *
 * @returns {boolean}
 */
function isOnError() {
    return ON_ERROR === true;
}

/**
 * Disable beneficiaries selection.
 */
function disableBeneficiariesSelection() {
    if (!document.getElementById(BENEFICIARIES_SELECT_INPUT_ID)) {
        return;
    }

    document.getElementById(BENEFICIARIES_SELECT_INPUT_ID).disabled = true;
    document.querySelectorAll('.' + DELETE_BUTTON_CLASS_NAME).forEach(element => {
        element.disabled = true;
    });
    document.getElementById(ADD_BENEFICIARY_BUTTON_ID).disabled = true;
}

/**
 * Get beneficiary values.
 */
function getBeneficiaryValues() {
    const beneficiaries = [];
    document.getElementById(BENEFICIARIES_LIST_ELEMENT_ID).querySelectorAll('p').forEach(function(element) {
        const id = element.querySelector('input[type="hidden"]').value;
        const amount = element.querySelector('input[type="text"]').value;

        if (amount > 0) {
            beneficiaries.push({
                beneficiary: id,
                amount
            });
        }
    });

    return beneficiaries;
}

/**
 * Get beneficiary display information.
 *
 * @returns {Array}
 */
function getBeneficiaryDisplayInformation() {
    const beneficiaries = [];
    document.getElementById(BENEFICIARIES_LIST_ELEMENT_ID).querySelectorAll('p').forEach(function(element) {
        const amount = element.querySelector('input[type="text"]').value;
        const label = element.querySelector('.beneficiary-label').innerText;

        if (amount > 0) {
            beneficiaries.push({
                label,
                amount
            });
        }
    });

    return beneficiaries;
}

/**
 * Get nb beneficiaries.
 *
 * @returns {number}
 */
function getNbBeneficiaries() {
    let nbBeneficiaries = 0;

    document.getElementById(BENEFICIARIES_LIST_ELEMENT_ID).querySelectorAll('input[type="text"]').forEach(function() {
        nbBeneficiaries++;
    });

    return nbBeneficiaries;
}

/**
 * Init events.
 */
function initEvents(args) {
    // handle amount splitting consistence when amount to split is modified by typing
    document.getElementById(TAX_AMOUNT_INPUT_ID).addEventListener('input', function(e) {
        if(!document.getElementById(TAX_AMOUNT_SPLITTING_ELEMENT_ID).checked || 0 === getNbBeneficiaries()) {
            return;
        }

        handleAmountSplittingConsistence(e.target);
    });

    // handle amount splitting consistence when amount to split is changed
    document.getElementById(TAX_AMOUNT_INPUT_ID).addEventListener('change', function(e) {
        if(!document.getElementById(TAX_AMOUNT_SPLITTING_ELEMENT_ID).checked || 0 === getNbBeneficiaries()) {
            return;
        }

        handleAmountSplittingConsistence(e.target);
    });

    document.getElementById(SUBFORM_SUBMIT_INPUT_ID).addEventListener('click', function(e) {
        e.preventDefault();

        // check tax amount validity
        if(!document.getElementById(TAX_AMOUNT_INPUT_ID).reportValidity()) {
            return;
        }

        // check splitted amounts validity
        let inputsValididity = true;
        document.getElementById(BENEFICIARIES_LIST_ELEMENT_ID).querySelectorAll('input[type="text"]').forEach(function(element) {
            if(!element.reportValidity()) {
                inputsValididity = false;
            }
        });

        if(!inputsValididity) {
            return;
        }

        removeErrorMessage();

        if(document.getElementById(TAX_AMOUNT_SPLITTING_ELEMENT_ID).checked) {
            handleAmountSplittingConsistence();
        }

        if(ON_ERROR) {
            return;
        }

        args.showCompanyInformationFields();
    });

    if (!document.getElementById(ADD_BENEFICIARY_BUTTON_ID)) {
        return;
    }

    document.getElementById(ADD_BENEFICIARY_BUTTON_ID).addEventListener('click', function(e) {
        e.preventDefault();

        const select = document.getElementById(BENEFICIARIES_SELECT_INPUT_ID);
        const selectedOption = select.options[select.selectedIndex];

        // no left option
        if(!selectedOption) {
            return;
        }

        // already existing
        if(BENEFICIARY_IDS_ADDED.indexOf(selectedOption.value) >= 0) {
            return;
        }

        addBeneficiaryFields(selectedOption.value, selectedOption.text, args.manageCompanyInformationFieldsVisibility);
    });
}

/**
 * Build beneficiary with specific information.
 *
 * @param element
 * @param label
 * @param amount
 * @returns {{label: *, amount: *, specificInformation: boolean, address: *, postalCode: *, city: *, country: *, contact: {title: *, lastName: *, firstName: *, function: *, phoneNumber: *, email: *}, bic: *, iban: *, checkRecipient: *, checkLabel: *, checkAddress: *, checkPostCode: *, checkCity: *, checkCountry: *}}
 */
function buildBeneficiaryWithSpecificInformation(element, label, amount) {
    return {
        label,
        amount,
        specificInformation: true,
        address : element.querySelector('.address-beneficiary').value,
        postalCode: element.querySelector('.postalCode-beneficiary').value,
        city: element.querySelector('.city-beneficiary').value,
        country: element.querySelector('.country-beneficiary').value,
        contact: {
            title: element.querySelector('.contact-title-beneficiary').value,
            lastName: element.querySelector('.contact-lastName-beneficiary').value,
            firstName:  element.querySelector('.contact-firstName-beneficiary').value,
            function: element.querySelector('.contact-function-beneficiary').value,
            phoneNumber:  element.querySelector('.contact-phoneNumber-beneficiary').value,
            email:  element.querySelector('.contact-email-beneficiary').value,
        },
        bic:  element.querySelector('.bic-beneficiary').value,
        iban: element.querySelector('.iban-beneficiary').value,
        checkRecipient: element.querySelector('.check-recipient-beneficiary').value,
        checkLabel: element.querySelector('.check-label-beneficiary').value,
        checkAddress: element.querySelector('.check-address-beneficiary').value,
        checkPostCode: element.querySelector('.check-postcode-beneficiary').value,
        checkCity: element.querySelector('.check-city-beneficiary').value,
        checkCountry: element.querySelector('.check-country-beneficiary').value
    };
}

module.exports = {
    initEvents,
    isOnError,
    disableBeneficiariesSelection,
    getBeneficiaryValues,
    getBeneficiaryDisplayInformation,
    getNbBeneficiaries
};

