const DISPLAY_NONE_CLASS_NAME = 'display-none';

/**
 * Check if element is visible.
 *
 * @param element
 * @returns {boolean}
 */
function isVisible(element) {
    return !element.classList.contains(DISPLAY_NONE_CLASS_NAME);
}

/**
 * Show by class name.
 *
 * @param className
 */
function showByClassName(className) {
    const elements = document.getElementsByClassName(className);

    for(let element of elements) {
        element.classList.remove(DISPLAY_NONE_CLASS_NAME);
    }
}

/**
 * Hide by class name.
 *
 * @param className
 */
function hideByClassName(className) {
    const elements = document.getElementsByClassName(className);

    for(let element of elements) {
        element.classList.add(DISPLAY_NONE_CLASS_NAME);
    }
}

/**
 * Show by element id.
 *
 * @param elementId
 */
function showByElementId(elementId) {
    document.getElementById(elementId).classList.remove(DISPLAY_NONE_CLASS_NAME);
}

/**
 * Hide by element id.
 *
 * @param elementId
 */
function hideByElementId(elementId) {
    document.getElementById(elementId).classList.add(DISPLAY_NONE_CLASS_NAME);
}

module.exports = {
    isVisible,
    showByClassName,
    hideByClassName,
    showByElementId,
    hideByElementId
};