/**
 * Create element from HTML.
 *
 * @param htmlString
 * @returns {Node | null}
 */
function createElementFromHTML(htmlString) {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    return div.firstChild;
}

module.exports = {
    createElementFromHTML
};