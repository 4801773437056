const requestHelper = require('./requestHelper');

module.exports = (paymentPromiseMode, paymentPromiseId) => {
    return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();

        req.addEventListener('load', function() {
            requestHelper.routePromise(req, resolve, reject);
        });

        req.addEventListener('error', function() {
            reject(req);
        });

        if (paymentPromiseMode === 'multi') {
            req.open('POST',  window.location.origin + '/api/payment/promise/multi/send-recap?ids=' + paymentPromiseId);
        } else {
            req.open('POST',  window.location.origin + '/api/payment/promise/' + paymentPromiseId + '/send-recap');
        }

        req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        req.send();
    });
};