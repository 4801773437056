const REMOVE_CONTACT_CLASS_NAME = 'remove-contact';
const CONTACT_CLASS_NAME = 'contact';
const DISPLAY_NONE_CLASS_NAME = 'display-none';

/**
 * Get first invisible receipt recipient.
 */
function getFirstInvisibleReceiptRecipient() {
    const contacts = document.getElementsByClassName(CONTACT_CLASS_NAME);

    for(let i in contacts) {
        if(contacts.hasOwnProperty(i)) {
            const contact = contacts[i];

            if(contact.classList.contains(DISPLAY_NONE_CLASS_NAME)) {
                return contact;
            }
        }
    }

    return null;
}

/**
 * Init events.
 *
 * @param addElementId
 * @param deleteClassName
 * @param options
 */
function initEvents(addElementId, deleteClassName, options) {
    // manage receipt recipients fields visibility
    document.getElementById(addElementId).addEventListener('click', function(e) {
        e.preventDefault();

       const firstInvisibleReceiptRecipient = getFirstInvisibleReceiptRecipient();
       if(!firstInvisibleReceiptRecipient) {
           return;
       }

       firstInvisibleReceiptRecipient.classList.remove(DISPLAY_NONE_CLASS_NAME);
    });

    // add event listener on delete button
    const removeQuerySelector = '.' + REMOVE_CONTACT_CLASS_NAME;
    document.querySelectorAll(removeQuerySelector).forEach(function(item) {
        item.addEventListener('click', function(e) {
            const target = e.target;
            const parent = target.parentElement;

            parent.classList.add(DISPLAY_NONE_CLASS_NAME);
        });
    });
}

module.exports = {
    initEvents
};