require('select2');
require('select2/dist/css/select2.css');
require('select2/dist/js/i18n/fr.js');

const PROPERTY_SEPARATOR = '_';

const SIRET_INPUT_ID_KEY = 'siret_input_id';

const COMPANY_NAME_INPUT_ID_KEY = 'company_name_input_id';
const COMPANY_ADDRESS_INPUT_ID_KEY = 'company_address_input_id';
const COMPANY_POSTAL_CODE_INPUT_ID_KEY = 'company_postalCode_input_id';
const COMPANY_CITY_INPUT_ID_KEY = 'company_city_input_id';
const COMPANY_PARENT_INPUT_ID_KEY = 'company_parent_input_id';

const DECISION_MAKER_TITLE_MADAM_RADIO_KEY = 'decision_maker_title_madam_radio';
const DECISION_MAKER_TITLE_MISTER_RADIO_KEY = 'decision_maker_title_mister_radio';
const DECISION_MAKER_LAST_NAME_INPUT_ID_KEY = 'decision_maker_lastName_input_id';
const DECISION_MAKER_FIRST_NAME_INPUT_ID_KEY  = 'decision_maker_firstName_input_id';
const DECISION_MAKER_EMAIL_INPUT_ID_KEY  = 'decision_maker_email_input_id';
const DECISION_MAKER_PHONE_NUMBER_INPUT_ID_KEY  = 'decision_maker_phoneNumber_input_id';
const DECISION_MAKER_FUNCTION_INPUT_ID_KEY  = 'decision_maker_function_input_id';

/**
 * Init events.
 */
function initEvents(searchSiretInputId, inputIdMapping) {
    const inputIdPropertyMap = populateInputIdPropertyMap(inputIdMapping);

    $(document).ready(function() {
        $('#' + searchSiretInputId).select2({
            language: "fr",
            minimumInputLength: 9,
            maximumInputLength: 14,
            ajax: {
                url: window.origin + '/api/payment/promise/siren/',
                dataType: 'json',
                method: 'GET',
                processResults: function (data) {
                    if(!Object.keys(data).length) {
                        return;
                    }

                    const results = data.map(paymentPromise => {
                        localStorage.setItem(paymentPromise.id, JSON.stringify(paymentPromise));

                        return {
                            id: paymentPromise.id,
                            text: buildPaymentPromiseLabel(paymentPromise)
                        };
                    });

                    return {
                        results
                    };
                }
            }
        });
    });

    $('#' + searchSiretInputId).on('select2:select', function(e) {
        const item = localStorage.getItem(e.target.value);

        if(!item) {
            return;
        }

        const paymentPromise = JSON.parse(item);

        bindValuesToInputs(inputIdPropertyMap, paymentPromise);
    });
}

/**
 * Build payment promise label.
 *
 * @param paymentPromise
 * @returns {string}
 */
function buildPaymentPromiseLabel(paymentPromise) {
    return paymentPromise.siret + ' - ' + paymentPromise.company.name + ' ' + paymentPromise.company.address + ' ' + (paymentPromise.company.postBox || '') + ' ' + paymentPromise.company.postalCode + ' ' + paymentPromise.company.city;
}

/**
 * Populate input property map.
 *
 * @param inputIdMapping
 */
function populateInputIdPropertyMap(inputIdMapping) {
    const inputIdPropertyMapping = {};

    inputIdPropertyMapping[inputIdMapping[SIRET_INPUT_ID_KEY]] = 'siret';
    inputIdPropertyMapping[inputIdMapping[COMPANY_NAME_INPUT_ID_KEY]] = 'company' + PROPERTY_SEPARATOR + 'name';
    inputIdPropertyMapping[inputIdMapping[COMPANY_ADDRESS_INPUT_ID_KEY]] = 'company' + PROPERTY_SEPARATOR + 'address';
    inputIdPropertyMapping[inputIdMapping[COMPANY_POSTAL_CODE_INPUT_ID_KEY]] = 'company' + PROPERTY_SEPARATOR + 'postalCode';
    inputIdPropertyMapping[inputIdMapping[COMPANY_CITY_INPUT_ID_KEY]] = 'company' + PROPERTY_SEPARATOR + 'city';
    inputIdPropertyMapping[inputIdMapping[COMPANY_PARENT_INPUT_ID_KEY]] = 'company' + PROPERTY_SEPARATOR + 'parent';

    // NOTE : ENABLE IT IF DECISION MAKER IS REQUIRED
    // inputIdPropertyMapping[inputIdMapping[DECISION_MAKER_TITLE_MADAM_RADIO_KEY]['element_id']] = {
    //     'property_access' : 'decisionMaker' + PROPERTY_SEPARATOR + 'title',
    //     'checked_value' : inputIdMapping[DECISION_MAKER_TITLE_MADAM_RADIO_KEY]['checked_value']
    // };
    // inputIdPropertyMapping[inputIdMapping[DECISION_MAKER_TITLE_MISTER_RADIO_KEY]['element_id']] = {
    //     'property_access' : 'decisionMaker' + PROPERTY_SEPARATOR + 'title',
    //     'checked_value' : inputIdMapping[DECISION_MAKER_TITLE_MISTER_RADIO_KEY]['checked_value']
    // };
    //
    // inputIdPropertyMapping[inputIdMapping[DECISION_MAKER_LAST_NAME_INPUT_ID_KEY]] = 'decisionMaker' + PROPERTY_SEPARATOR + 'lastName';
    // inputIdPropertyMapping[inputIdMapping[DECISION_MAKER_FIRST_NAME_INPUT_ID_KEY]] = 'decisionMaker' + PROPERTY_SEPARATOR + 'firstName';
    // inputIdPropertyMapping[inputIdMapping[DECISION_MAKER_EMAIL_INPUT_ID_KEY]] = 'decisionMaker' + PROPERTY_SEPARATOR + 'email';
    // inputIdPropertyMapping[inputIdMapping[DECISION_MAKER_PHONE_NUMBER_INPUT_ID_KEY]] = 'decisionMaker' + PROPERTY_SEPARATOR + 'phoneNumber';
    // inputIdPropertyMapping[inputIdMapping[DECISION_MAKER_FUNCTION_INPUT_ID_KEY]] = 'decisionMaker' + PROPERTY_SEPARATOR + 'function';

    return inputIdPropertyMapping;
}

/**
 * Bind values to inputs.
 *
 * @param inputIdPropertyMap
 * @param paymentPromise
 */
function bindValuesToInputs(inputIdPropertyMap, paymentPromise) {
    for(const [ key, value ] of Object.entries(inputIdPropertyMap)) {
        // case radio
        if(typeof value === 'object') {
            const propertyValue = accessPropertyValue(paymentPromise, value['property_access']);
            if(propertyValue === value['checked_value']) {
                document.getElementById(key).checked = true;
            }
        }
        // case input
        else {
            document.getElementById(key).value = accessPropertyValue(paymentPromise, value);
        }
    }
}

/**
 * Access property value.
 *
 * @param object
 * @param propertyPath
 * @returns {*}
 */
function accessPropertyValue(object, propertyPath) {
    const splittedPropertyPath = propertyPath.split(PROPERTY_SEPARATOR);

    let value = object;
    splittedPropertyPath.forEach(element => {
        value = value[element];
    });

    return value;
}

module.exports = {
    initEvents
};