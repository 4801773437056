const declarePaymentPromise = require('../api/declarePaymentPromise');
const beneficiaries = require('./paymentPromise/beneficiaries');
const paymentPromiseAutofill = require('../autofill/paymentPromiseAutofill');
const receiptRecipients = require('./paymentPromise/receiptRecipients');
const frontDisplayUtils = require('../front/utils/displayUtils');
const numberUtils = require('../utils/numberUtils');

const DISPLAY_NONE_CLASS_NAME = 'display-none';

const SEARCH_SIRET_INPUT_ID = 'search-siret';
const SIRET_INPUT_ID = 'siret';

const TAX_AMOUNT_INPUT_ID = 'amount-school';
const PAYMENT_PROMISE_STEP_ELEMENT_ID = 'pay';

const DECISION_MADAM_TITLE_RADIO_ID = 'contact_madame';
const DECISION_MISTER_TITLE_RADIO_ID = 'contact_monsieur';
const DECISION_LAST_NAME_INPUT_ID = 'contact_name';
const DECISION_FIRST_NAME_INPUT_ID = 'contact_forname';
const DECISION_EMAIL_INPUT_ID = 'contact_email';
const DECISION_PHONE_INPUT_ID = 'contact_phone';
const DECISION_FUNCTION_INPUT_ID = 'contact_function';

const INDEX_PATTERN = '_index_';
const RECIPIENT_MADAM_TITLE_RADIO_ID_PATTERN = 'contact_other_' + INDEX_PATTERN + '_madame';
const RECIPIENT_MISTER_TITLE_RADIO_ID_PATTERN = 'contact_other_' + INDEX_PATTERN + '_monsieur';
const RECIPIENT_LAST_NAME_INPUT_ID_PATTERN = 'contact_other_' + INDEX_PATTERN + '_name';
const RECIPIENT_FIRST_NAME_INPUT_ID_PATTERN = 'contact_other_' + INDEX_PATTERN + '_forname';
const RECIPIENT_EMAIL_INPUT_ID_PATTERN = 'contact_other_' + INDEX_PATTERN + '_email';
const RECIPIENT_PHONE_INPUT_ID_PATTERN = 'contact_other_' + INDEX_PATTERN + '_phone';
const RECIPIENT_FUNCTION_INPUT_ID_PATTERN = 'contact_other_' + INDEX_PATTERN + '_function';

const COMPANY_INFORMATION_ELEM_ID = 'company-information';
const COMPANY_NAME_INPUT_ID = 'social';
const COMPANY_ADDRESS_INPUT_ID = 'address';
const COMPANY_POSTBOX_INPUT_ID = 'postbox';
const COMPANY_POSTAL_CODE_INPUT_ID = 'postal_code';
const COMPANY_CITY_INPUT_ID = 'town';
const COMPANY_PARENT_INPUT_ID = 'parent';

const NOTES_TEXTAREA_ID = 'remarques';
const BENEFICIARIES_INFORMATION_DISTRIBUTIONS_TEXTAREA_ID = 'beneficiaries-information-distributions';

const TAX_AMOUNT_SPLITTING_ELEMENT_ID = 'diplome_ou_site_1';
const NO_TAX_AMOUNT_SPLITTING_ELEMENT_ID = 'diplome_ou_site_2';

const BENEFICIARY_ADDING_WRAPPER_ELEMENT_ID = 'add-beneficiary-part';

let BENEFICIARIES_PRESENCE = false;

// used to map attribute with input id to render form errors when html 5 validation fails
const ATTR_INPUT_ID_MAP = {
    'siret' : SIRET_INPUT_ID,
    'amount' : TAX_AMOUNT_INPUT_ID,
    'company_name' : COMPANY_NAME_INPUT_ID,
    'company_address' : COMPANY_ADDRESS_INPUT_ID,
    'company_postalCode' : COMPANY_POSTAL_CODE_INPUT_ID,
    'company_city' : COMPANY_CITY_INPUT_ID,
    'company_parent' : COMPANY_PARENT_INPUT_ID,
    'decisionMaker_title' : DECISION_MADAM_TITLE_RADIO_ID,
    'decisionMaker_lastName' : DECISION_LAST_NAME_INPUT_ID,
    'decisionMaker_firstName' : DECISION_FIRST_NAME_INPUT_ID,
    'decisionMaker_email' : DECISION_EMAIL_INPUT_ID,
    'decisionMaker_phoneNumber' : DECISION_PHONE_INPUT_ID,
    'decisionMaker_function' : DECISION_FUNCTION_INPUT_ID,
    'notes' : NOTES_TEXTAREA_ID,
};

/**
 * Show company information fields.
 */
function showCompanyInformationFields() {
    document.getElementById(COMPANY_INFORMATION_ELEM_ID).classList.remove(DISPLAY_NONE_CLASS_NAME);
}

/**
 * Hide company information fields.
 */
function hideCompanyInformationFields() {
    document.getElementById(COMPANY_INFORMATION_ELEM_ID).classList.add(DISPLAY_NONE_CLASS_NAME);
}


/**
 * Disable tax amount field.
 */
function disableTaxAmountField() {
    const taxAmountElement = document.getElementById(TAX_AMOUNT_INPUT_ID);
    taxAmountElement.disabled = true;
}

/**
 * Set tax amount.
 *
 * @param taxAmount
 */
function setTaxAmount(taxAmount) {
    const taxAmountElement = document.getElementById(TAX_AMOUNT_INPUT_ID);

    taxAmountElement.value = taxAmount;
}

/**
 * Enable tax amount field.
 */
function enableTaxAmountField() {
    const taxAmountElement = document.getElementById(TAX_AMOUNT_INPUT_ID);
    taxAmountElement.value = '';
    taxAmountElement.disabled = false;
}

/**
 * Show recap receiver fields.
 */
function showReceiptReceiverFields() {
    document.getElementById('contacts').classList.remove(DISPLAY_NONE_CLASS_NAME);
}

/**
 * Hide recap receiver fields.
 */
function hideReceiptReceiverFields() {
    document.getElementById('contacts').classList.add(DISPLAY_NONE_CLASS_NAME);
}

/**
 * Clean form errors.
 */
function cleanFormErrors() {
    const elements = document.getElementsByClassName('hf-warning');
    for(let i in elements) {
        if(elements.hasOwnProperty(i)) {
            const element = elements[i];
            element.remove();
        }
    }
}

/**
 * Add error div.
 *
 * @param inputId
 * @param errorMessage
 */
function addErrorDiv(inputId, errorMessage) {
    const errorDiv = document.createElement('div');
    errorDiv.classList.add('hf-warning');
    errorDiv.setAttribute('aria-live', 'polite');
    errorDiv.innerHTML = errorMessage;

    document.getElementById(inputId).parentElement.appendChild(errorDiv);
}

/**
 * Render form errors.
 *
 * @param errors
 * @param parentName
 */
function renderFormErrors(errors, parentName = null) {
    for(let attr in errors) {
        if(errors.hasOwnProperty(attr)) {
            const attrErrors = errors[attr];

            if('receiptRecipients' === attr) {
                renderReceiptRecipientErrors(attrErrors);
            } else if(Array.isArray(attrErrors)) {
                if(attrErrors.length) {
                    const attrError = attrErrors[0];
                    const attrTarget = parentName ? parentName + '_' + attr : attr;
                    const inputId = ATTR_INPUT_ID_MAP[attrTarget];

                    addErrorDiv(inputId, attrError);
                }
            } else if(typeof attrErrors === 'object' && attrErrors !== null) {
                renderFormErrors(attrErrors, attr);
            }
        }
    }
}

/**
 * Render receipt recipient errors.
 *
 * @param receiptRecipients
 */
function renderReceiptRecipientErrors(receiptRecipients) {
    for (let i in receiptRecipients) {
        if(receiptRecipients.hasOwnProperty(i)) {
            const receiptRecipient = receiptRecipients[i];
            const person = receiptRecipient.person;

            let hasErrors = false;
            for(let attr in person) {
                if(person.hasOwnProperty(attr)) {
                    const errors = person[attr];

                    if(errors.length) {
                        hasErrors = true;
                    }
                }
            }

            if(hasErrors) {
                const index = parseInt(i) + 1;
                const wrapperElementId = 'contact_' + index;

                const errorDiv = document.createElement('div');
                errorDiv.classList.add('hf-warning');
                errorDiv.setAttribute('aria-live', 'polite');
                errorDiv.innerHTML = 'Ce contact comporte des champs invalides. Veuillez compléter les champs obligatoires.';
                document.getElementById(wrapperElementId).appendChild(errorDiv);
            }
        }
    }
}

/**
 * Get amount.
 *
 * @returns {*}
 */
function getAmount() {
    return document.getElementById(TAX_AMOUNT_INPUT_ID).value;
}

/**
 * Get receipt recipient from index.
 *
 * @param index
 * @returns {{person}}
 */
function getReceiptRecipient(index) {
    let receiptRecipient = {};
    const radioReceiptRecipientMadam = document.getElementById(RECIPIENT_MADAM_TITLE_RADIO_ID_PATTERN.replace(INDEX_PATTERN, index));
    const radioReceiptRecipientMister = document.getElementById(RECIPIENT_MISTER_TITLE_RADIO_ID_PATTERN.replace(INDEX_PATTERN, index));
    let receiptRecipientTitle = null;
    if(radioReceiptRecipientMadam.checked) {
        receiptRecipientTitle = radioReceiptRecipientMadam.value;
    } else if(radioReceiptRecipientMister.checked) {
        receiptRecipientTitle = radioReceiptRecipientMister.value;
    }
    receiptRecipient['title'] = receiptRecipientTitle;
    receiptRecipient['lastName'] = document.getElementById(RECIPIENT_LAST_NAME_INPUT_ID_PATTERN.replace(INDEX_PATTERN, index)).value;
    receiptRecipient['firstName'] = document.getElementById(RECIPIENT_FIRST_NAME_INPUT_ID_PATTERN.replace(INDEX_PATTERN, index)).value;
    receiptRecipient['function'] = document.getElementById(RECIPIENT_FUNCTION_INPUT_ID_PATTERN.replace(INDEX_PATTERN, index)).value;
    receiptRecipient['email'] = document.getElementById(RECIPIENT_EMAIL_INPUT_ID_PATTERN.replace(INDEX_PATTERN, index)).value;
    receiptRecipient['phoneNumber'] = document.getElementById(RECIPIENT_PHONE_INPUT_ID_PATTERN.replace(INDEX_PATTERN, index)).value;

    return { person: receiptRecipient };
}

/**
 * Populate payment promise from form fields.
 */
function populatePaymentPromise() {
    const amount = numberUtils.convertToFloat(document.getElementById(TAX_AMOUNT_INPUT_ID).value);
    const siret = document.getElementById(SIRET_INPUT_ID).value;
    const company = {};
    company['name'] = document.getElementById(COMPANY_NAME_INPUT_ID).value;
    company['address'] = document.getElementById(COMPANY_ADDRESS_INPUT_ID).value;
    company['postBox'] = document.getElementById(COMPANY_POSTBOX_INPUT_ID).value;
    company['postalCode'] = document.getElementById(COMPANY_POSTAL_CODE_INPUT_ID).value;
    company['city'] = document.getElementById(COMPANY_CITY_INPUT_ID).value;
    company['parent'] = document.getElementById(COMPANY_PARENT_INPUT_ID).value;

    const decisionMaker = {};
    const radioDecisionMakerMadam = document.getElementById(DECISION_MADAM_TITLE_RADIO_ID);
    const radioDecisionMakerMister = document.getElementById(DECISION_MISTER_TITLE_RADIO_ID);
    let decisionMakerTitle = null;
    if(radioDecisionMakerMadam.checked) {
        decisionMakerTitle = radioDecisionMakerMadam.value;
    } else if(radioDecisionMakerMister.checked) {
        decisionMakerTitle = radioDecisionMakerMister.value;
    }

    decisionMaker['title'] = decisionMakerTitle;
    decisionMaker['lastName'] = document.getElementById(DECISION_LAST_NAME_INPUT_ID).value;
    decisionMaker['firstName'] = document.getElementById(DECISION_FIRST_NAME_INPUT_ID).value;
    decisionMaker['function'] = document.getElementById(DECISION_FUNCTION_INPUT_ID).value;
    decisionMaker['email'] = document.getElementById(DECISION_EMAIL_INPUT_ID).value;
    decisionMaker['phoneNumber'] = document.getElementById(DECISION_PHONE_INPUT_ID).value;
    const notes = document.getElementById(NOTES_TEXTAREA_ID).value;

    let paymentPromiseBeneficiaries = [];
    let beneficiariesInformationDistributions = null;
    if(BENEFICIARIES_PRESENCE && document.getElementById(TAX_AMOUNT_SPLITTING_ELEMENT_ID).checked) {
        paymentPromiseBeneficiaries = beneficiaries.getBeneficiaryValues();
        const beneficiariesInformationDistributionsTextareaField = document.getElementById(BENEFICIARIES_INFORMATION_DISTRIBUTIONS_TEXTAREA_ID);
        if (beneficiariesInformationDistributionsTextareaField) {
            beneficiariesInformationDistributions = beneficiariesInformationDistributionsTextareaField.value;
        }
    }

    return {
        amount,
        siret,
        company,
        decisionMaker,
        notes,
        paymentPromiseBeneficiaries,
        beneficiariesInformationDistributions,
        ...getUtmAttributes()
    };
}

/**
 * Send payment promise declaration request.
 */
function sendPaymentPromiseDeclarationRequest() {
    cleanFormErrors();
    return declarePaymentPromise(populatePaymentPromise())
        .catch(request => {
            const status = request.status;

            if(status === 400) {
                let response = JSON.parse(request.response);
                renderFormErrors(response.errors);
            } else {
                alert('Une erreur survenue. Navré pour la gêne occasionnée, veuillez contacter votre administrateur.');
            }
        });
}

/**
 * Disable fields.
 */
function disableFields() {
    const paymentPromiseForm = document.getElementById(PAYMENT_PROMISE_STEP_ELEMENT_ID).parentElement;
    const inputs = paymentPromiseForm.getElementsByTagName('input');

    // disable inputs
    for(const i in inputs) {
        inputs[i].disabled = true;
    }

    // disable buttons
    const buttons = paymentPromiseForm.getElementsByClassName('button');
    for(let i in buttons) {
        buttons[i].disabled = true;
    }

    // disable textarea
    document.getElementById(NOTES_TEXTAREA_ID).disabled = true;

    if(BENEFICIARIES_PRESENCE) {
        beneficiaries.disableBeneficiariesSelection();
    }
}

/**
 * Manage receipt receiver fields visibility.
 */
function manageReceiptReceiverFieldsVisibility() {
    hideReceiptReceiverFields();
}

/**
 * Check if beneficiaries are on error.
 *
 * @returns {boolean}
 */
function areBeneficiariesOnError() {
    return BENEFICIARIES_PRESENCE ? beneficiaries.isOnError() && document.getElementById(TAX_AMOUNT_SPLITTING_ELEMENT_ID).checked : false;
}

/**
 * Manage company information fields visibility.
 */
function manageCompanyInformationFieldsVisibility() {
    if(BENEFICIARIES_PRESENCE) {
        if(document.getElementById(TAX_AMOUNT_SPLITTING_ELEMENT_ID).checked && beneficiaries.getNbBeneficiaries() && !beneficiaries.isOnError() && document.getElementById(TAX_AMOUNT_INPUT_ID).validity.valid) {
            showCompanyInformationFields();

            return;
        }

        if(document.getElementById(NO_TAX_AMOUNT_SPLITTING_ELEMENT_ID).checked && document.getElementById(TAX_AMOUNT_INPUT_ID).validity.valid) {
            showCompanyInformationFields();

            return;
        }

        hideCompanyInformationFields();

        return;
    }

    if(document.getElementById(TAX_AMOUNT_INPUT_ID).validity.valid) {
        showCompanyInformationFields();

        return;
    }

    hideCompanyInformationFields();
}

/**
 * Manage beneficiaries visibility.
 */
function manageBeneficiariesVisibility() {
    if(!BENEFICIARIES_PRESENCE) {
        return;
    }

    if(document.getElementById(TAX_AMOUNT_SPLITTING_ELEMENT_ID).checked) {
        document.getElementById(BENEFICIARY_ADDING_WRAPPER_ELEMENT_ID).classList.remove(DISPLAY_NONE_CLASS_NAME);
        document.getElementById(COMPANY_INFORMATION_ELEM_ID).classList.add(DISPLAY_NONE_CLASS_NAME);

        return;
    }

    document.getElementById(BENEFICIARY_ADDING_WRAPPER_ELEMENT_ID).classList.add(DISPLAY_NONE_CLASS_NAME);
    manageCompanyInformationFieldsVisibility();
}

/**
 * Init events.
 */
function initEvents(beneficiariesPresence) {
    BENEFICIARIES_PRESENCE = beneficiariesPresence;

    // manage recap receiver fields
    document.getElementById('contact').addEventListener('click', function() {
        manageReceiptReceiverFieldsVisibility();
    });

    document.getElementById(TAX_AMOUNT_INPUT_ID).addEventListener('input', () => {
        manageCompanyInformationFieldsVisibility();
    });

    if(beneficiariesPresence) {
        // add event listeners to tax amount splitting (yes/no) radio inputs to manage beneficiaries visibility
        document.getElementById(TAX_AMOUNT_SPLITTING_ELEMENT_ID).addEventListener('change', function() {
            manageBeneficiariesVisibility();
        });

        document.getElementById(NO_TAX_AMOUNT_SPLITTING_ELEMENT_ID).addEventListener('change', function() {
            manageBeneficiariesVisibility();
        });

        document.getElementById(PAYMENT_PROMISE_STEP_ELEMENT_ID).parentElement.addEventListener('validate', function() {
            if(!document.getElementById('diplome_ou_site_1').checked) {
                document.getElementById('beneficiaries').innerHTML = '';
            }
        });

        beneficiaries.initEvents({
            showCompanyInformationFields,
            manageCompanyInformationFieldsVisibility
        });

        if(document.getElementById(NO_TAX_AMOUNT_SPLITTING_ELEMENT_ID).checked) {
            manageCompanyInformationFieldsVisibility();
        }
    }

    paymentPromiseAutofill.initEvents(SEARCH_SIRET_INPUT_ID, {
        'siret_input_id' : SIRET_INPUT_ID,
        'company_name_input_id' : COMPANY_NAME_INPUT_ID,
        'company_address_input_id' : COMPANY_ADDRESS_INPUT_ID,
        'company_postalCode_input_id' : COMPANY_POSTAL_CODE_INPUT_ID,
        'company_city_input_id' : COMPANY_CITY_INPUT_ID,
        'company_parent_input_id' : COMPANY_PARENT_INPUT_ID,
        'decision_maker_lastName_input_id' : DECISION_LAST_NAME_INPUT_ID,
        'decision_maker_firstName_input_id' : DECISION_FIRST_NAME_INPUT_ID,
        'decision_maker_email_input_id' : DECISION_EMAIL_INPUT_ID,
        'decision_maker_phoneNumber_input_id' : DECISION_PHONE_INPUT_ID,
        'decision_maker_function_input_id' : DECISION_FUNCTION_INPUT_ID,
        'decision_maker_title_madam_radio' : {
            'checked_value' : 'MADAME',
            'element_id' : DECISION_MADAM_TITLE_RADIO_ID
        },
        'decision_maker_title_mister_radio' : {
            'checked_value' : 'MONSIEUR',
            'element_id' : DECISION_MISTER_TITLE_RADIO_ID
        }
    });

    receiptRecipients.initEvents('add-contact', 'delete-contact');

    window.addEventListener('load', e => {
        if(document.getElementById(NO_TAX_AMOUNT_SPLITTING_ELEMENT_ID).checked) {
            manageCompanyInformationFieldsVisibility();
        }
    });
}

/**
 * Get beneficiary display information
 */
function getBeneficiaryDisplayInformation() {
    return beneficiaries.getBeneficiaryDisplayInformation();
}

/**
 * Get UTM attributes.
 */
function getUtmAttributes() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return {
        utmSource: params.utm_source || null,
        utmCampaign: params.utm_campaign || null
    };
}

module.exports = {
    manageCompanyInformationFieldsVisibility,
    areBeneficiariesOnError,
    setTaxAmount,
    sendPaymentPromiseDeclarationRequest,
    getAmount,
    disableFields,
    manageReceiptReceiverFieldsVisibility,
    manageBeneficiariesVisibility,
    initEvents,
    getBeneficiaryDisplayInformation
};
