require('./scss/main.scss');
import hyperform from 'hyperform';
const app = require('./js/front/app');

hyperform(window);

app.initEvents();

if (module.hot) {
    module.hot.accept();
}
