const simulationHandler = require('./simulationHandler');
const paymentPromiseHandler = require('./paymentPromiseHandler');
const recapHandler = require('./recapHandler');

// beneficiaries presence discriminant
const BENEFICIARIES_PRESENCE_DISCR_ELEMENT_ID = 'diplome_ou_site_1';

const DISPLAY_NONE_CLASS_NAME = 'display-none';

// step state
const IS_OPENED_CLASS_NAME = 'is-opened';

// step element ids
// main
const SIMULATION_STEP_ELEMENT_ID = 'calculate';
const PAYMENT_PROMISE_STEP_ELEMENT_ID = 'pay';

const LESS_THAN_250EMP_ID = 'radio1';
const MORE_THAN_250EMP_ID = 'radio2';

const CONFIRMATION_MODAL_ELEMENT_ID = 'payment-promise-confirmed-modal';
const CLOSE_CONFIRMATION_MODAL_BUTTON_ID = 'close-payment-promise-confirmed-modal';

/**
 * Hide step.
 *
 * @param elementId
 */
function hideStep(elementId) {
    document.getElementById(elementId).parentElement.classList.add(DISPLAY_NONE_CLASS_NAME);
}

/**
 * Show step.
 *
 * @param elementId
 */
function showStep(elementId) {
    document.getElementById(elementId).parentElement.classList.remove(DISPLAY_NONE_CLASS_NAME);
}

/**
 * Close step.
 *
 * @param elementId
 */
function closeStep(elementId) {
    document.getElementById(elementId).classList.remove(IS_OPENED_CLASS_NAME);
}

/**
 * Open step.
 *
 * @param elementId
 */
function openStep(elementId) {
    document.getElementById(elementId).classList.add(IS_OPENED_CLASS_NAME);
}

/**
 * Disable first step fields.
 */
function disableFirstStepFields() {
    document.getElementById('oui').disabled = true;
    document.getElementById('non').disabled = true;
}

/**
 * Disable all fields.
 */
function disableAllFields() {
    disableFirstStepFields();
    simulationHandler.disableFields();
    paymentPromiseHandler.disableFields();
}

/**
 * Handle known tax amount.
 */
function handleTaxKnownAmount() {
    hideStep(SIMULATION_STEP_ELEMENT_ID);
    openStep(PAYMENT_PROMISE_STEP_ELEMENT_ID);
    simulationHandler.cleanAndHideTaxAmountSimulationFields();
    window.location.hash = PAYMENT_PROMISE_STEP_ELEMENT_ID;
}

/**
 * Handle unknown tax amount.
 */
function handleUnknownTaxAmount() {
    closeStep(PAYMENT_PROMISE_STEP_ELEMENT_ID);
    showStep(SIMULATION_STEP_ELEMENT_ID);
    openStep(SIMULATION_STEP_ELEMENT_ID);
    window.location.hash = SIMULATION_STEP_ELEMENT_ID;
}

/**
 * Init events.
 */
function initEvents() {
    // if "oui" is checked then
    // we clean tax amount simulation fields,
    // we close simulation step
    // and we open payment promise step
    document.getElementById('oui').addEventListener('change', function() {
        handleTaxKnownAmount();
    });

    // if "no" is checked then
    // we close payment promise step
    // and we open simulation step
    document.getElementById('non').addEventListener('change', function() {
        handleUnknownTaxAmount();
    });

    const calculateForm = document.getElementById(SIMULATION_STEP_ELEMENT_ID).parentElement;
    calculateForm.addEventListener('submit', function(e) {
        e.preventDefault();
        const amountToPayElement = simulationHandler.getAmountToPayElement();
        // paymentPromiseHandler.setTaxAmount(amountToPayElement.innerText);
        openStep(PAYMENT_PROMISE_STEP_ELEMENT_ID);
        window.location.hash = PAYMENT_PROMISE_STEP_ELEMENT_ID;
    });

    // send request to record payment promise
    const paymentPromiseForm = document.getElementById(PAYMENT_PROMISE_STEP_ELEMENT_ID).parentElement;
    paymentPromiseForm.addEventListener('submit', function(e) {
        e.preventDefault();

        if(!confirm('Etes-vous sûr de vouloir valider votre promesse de versement ?')) {
            return;
        }

        // if CGU aren't accepted - general alert
        if(!document.getElementById('confirm').checked) {
            alert('Vous devez accepter les conditions de confidentialité pour valider pour votre promesse de règlement.');
            return;
        }

        // if there are errors on beneficiaries amount splitting - general alert
        if(paymentPromiseHandler.areBeneficiariesOnError()) {
            alert('La somme des montants à verser aux diplômes ou sites n’est pas égale au montant global que vous souhaitez verser à l’établissement. Merci de corriger.');
            return;
        }

        paymentPromiseHandler.sendPaymentPromiseDeclarationRequest()
            .then(request => {
                const response = JSON.parse(request.response);
                const paymentPromiseIds = response.ids;
                const surveyToken = response.survey_token;

                disableAllFields();
                recapHandler.replacePaymentPromiseAmount(paymentPromiseHandler.getAmount());
                recapHandler.replacePaymentPromiseIds(paymentPromiseIds);
                if(surveyToken) {
                    recapHandler.replacePaymentPromiseSurveyToken(surveyToken);
                }

                recapHandler.openRecap();
                recapHandler.addBeneficiaries(paymentPromiseHandler.getBeneficiaryDisplayInformation());

                document.getElementById(CONFIRMATION_MODAL_ELEMENT_ID).classList.add('open');
            });
    });

    const beneficiariesDiscrElement = document.getElementById(BENEFICIARIES_PRESENCE_DISCR_ELEMENT_ID);
    const beneficiariesPresence = beneficiariesDiscrElement !== null;

    simulationHandler.initEvents();
    paymentPromiseHandler.initEvents(beneficiariesPresence);
    recapHandler.initEvents();

    document.getElementById(CLOSE_CONFIRMATION_MODAL_BUTTON_ID).addEventListener('click', e => {
        e.preventDefault();
        document.getElementById(CONFIRMATION_MODAL_ELEMENT_ID).classList.remove('open');
    });

    window.addEventListener('load', e => {
        // first step
        if(document.getElementById('oui').checked) {
            handleTaxKnownAmount();
        } else if(document.getElementById('non').checked) {
            handleUnknownTaxAmount();
        }

        // second step
        const radioLessThan250 = document.getElementById(LESS_THAN_250EMP_ID);
        const radioMoreThan250 = document.getElementById(MORE_THAN_250EMP_ID);
        if(radioLessThan250.checked) {
            simulationHandler.showCompanyLengthRelatedFields(radioLessThan250.value);
        } else if(radioMoreThan250.checked) {
            simulationHandler.showCompanyLengthRelatedFields(radioMoreThan250.value);
        }

        // third step
        paymentPromiseHandler.manageReceiptReceiverFieldsVisibility();
        paymentPromiseHandler.manageCompanyInformationFieldsVisibility();
        paymentPromiseHandler.manageBeneficiariesVisibility();
    })
}

module.exports = {
    initEvents
};