const calculateTaxAmount = require('../api/calculateTaxAmount');
const numberUtils = require('../utils/numberUtils');
const displayUtils = require('./utils/displayUtils');

const SIMULATION_STEP_ELEMENT_ID = 'calculate';

const HIDDEN_CLASS = 'display-none';

// company length
const LESS_THAN_250EMP_VAL = 'less';
const MORE_THAN_250EMP_VAL = 'more';

const LESS_THAN_250EMP_ID = 'radio1';
const MORE_THAN_250EMP_ID = 'radio2';

// input ids
const PAYROLL_AMOUNT_INPUT_ID = 'taxe';
const DEDUCTION_AMOUNT_INPUT_ID = 'deduction';

// element ids
const NOTE_ELEM_ID = 'note';
const AMOUNT_TO_PAY_ELEM_ID = 'montant-a-verser';
const DEDUCTION_LESS_CLASS_NAME = 'deduction-less';
const DEDUCTION_MORE_CLASS_NAME = 'deduction-more';

// submit id
const SUBMIT_ELEM_ID = 'go-to-step-3';

/**
 * Show from input id.
 *
 * @param childElementId
 */
function showFromChildElementId(childElementId) {
    document.getElementById(childElementId).parentElement.classList.remove(HIDDEN_CLASS);
}

/**
 * Hide from input id.
 *
 * @param childElementId
 */
function hideFromChildElementId(childElementId) {
    document.getElementById(childElementId).parentElement.classList.add(HIDDEN_CLASS);
}

/**
 * Empty text input from id.
 * @param inputId
 */
function emptyTextInput(inputId) {
    document.getElementById(inputId).value = '';
}

/**
 * Uncheck radio input from id.
 *
 * @param inputId
 */
function uncheckRadioInput(inputId) {
    document.getElementById(inputId).checked = false;
}

/**
 * Clean amount to pay element.
 */
function cleanAmountToPaySpan() {
    const spanElem = getAmountToPayElement();

    spanElem.innerText = '';
}

/**
 * Get amount to pay element.
 *
 * @returns {*}
 */
function getAmountToPayElement() {
    const children = document.getElementById(AMOUNT_TO_PAY_ELEM_ID).children;
    let spanElement = null;
    for(let i in children) {
        const element = children[i];
        if(element.nodeName === 'SPAN' && element.classList.contains('input')) {
            spanElement = element;
        }
    }

    return spanElement;
}

/**
 * Clean tax amount simulation fields.
 */
function cleanAndHideTaxAmountSimulationFields() {
    displayUtils.hideByElementId(NOTE_ELEM_ID);
    hideFromChildElementId(PAYROLL_AMOUNT_INPUT_ID);
    hideFromChildElementId(DEDUCTION_AMOUNT_INPUT_ID);
    displayUtils.hideByElementId(AMOUNT_TO_PAY_ELEM_ID);
    displayUtils.hideByElementId(SUBMIT_ELEM_ID);

    uncheckRadioInput(LESS_THAN_250EMP_ID);
    uncheckRadioInput(MORE_THAN_250EMP_ID);
    emptyTextInput(PAYROLL_AMOUNT_INPUT_ID);
    emptyTextInput(DEDUCTION_AMOUNT_INPUT_ID);
    cleanAmountToPaySpan();
}

/**
 * Show simulation company length related fields.
 *
 * @param lengthIndicator
 */
function showCompanyLengthRelatedFields(lengthIndicator) {
    displayUtils.showByElementId(NOTE_ELEM_ID);
    showFromChildElementId(PAYROLL_AMOUNT_INPUT_ID);
    displayUtils.showByElementId(AMOUNT_TO_PAY_ELEM_ID);

    switch(lengthIndicator) {
        case LESS_THAN_250EMP_VAL :
            displayUtils.hideByClassName(DEDUCTION_MORE_CLASS_NAME);
            displayUtils.showByClassName(DEDUCTION_LESS_CLASS_NAME);
            showFromChildElementId(DEDUCTION_AMOUNT_INPUT_ID);
            break;
        case MORE_THAN_250EMP_VAL :
            displayUtils.showByClassName(DEDUCTION_MORE_CLASS_NAME);
            displayUtils.hideByClassName(DEDUCTION_LESS_CLASS_NAME);
            showFromChildElementId(DEDUCTION_AMOUNT_INPUT_ID);
            break;
        default:
            // same as "less" case by default
            displayUtils.hideByClassName(DEDUCTION_MORE_CLASS_NAME);
            displayUtils.showByClassName(DEDUCTION_LESS_CLASS_NAME);
            showFromChildElementId(DEDUCTION_AMOUNT_INPUT_ID);
    }
}

/**
 * Init tax amount calculation events.
 */
function initTaxAmountCalculationEvents() {
    const calculateForm = document.getElementById(SIMULATION_STEP_ELEMENT_ID).parentElement;

    calculateForm.addEventListener('input', function(e) {
        if(e.target.type === 'radio') {
            return;
        }

        const spanElem = getAmountToPayElement();
        // check if data values are invalid
        if(!e.target.checkValidity() || !calculateForm.checkValidity()) {
            spanElem.innerText = 0;
            displayUtils.hideByElementId(SUBMIT_ELEM_ID);
            return;
        }

        const payrollAmountElement = document.getElementById(PAYROLL_AMOUNT_INPUT_ID);
        const deductionAmountElement = document.getElementById(DEDUCTION_AMOUNT_INPUT_ID);

        calculateTaxAmount(numberUtils.convertToFloat(payrollAmountElement.value), numberUtils.convertToFloat(deductionAmountElement.value))
        .then(request => {
            const result = JSON.parse(request.response);
            const tax_amount = result.tax_amount;

            if(tax_amount < 0) {
                spanElem.innerText = 0;
                displayUtils.hideByElementId(SUBMIT_ELEM_ID);

                return;
            }

            spanElem.innerText = tax_amount;
            displayUtils.showByElementId(SUBMIT_ELEM_ID);
        });
    });
}

/**
 * Disable fields.
 */
function disableFields() {
    const calculateForm = document.getElementById(SIMULATION_STEP_ELEMENT_ID).parentElement;
    const inputs = calculateForm.getElementsByTagName('input');

    for(let i in inputs) {
        inputs[i].disabled = true;
    }

    const buttons = calculateForm.getElementsByClassName('button');
    for(let i in buttons) {
        buttons[i].disabled = true;
    }
}

/**
 * Init events.
 */
function initEvents() {
    // display fields in function of chosen company length
    document.getElementById(LESS_THAN_250EMP_ID).addEventListener('change', function(e) {
        showCompanyLengthRelatedFields(e.target.value);
    });

    document.getElementById(MORE_THAN_250EMP_ID).addEventListener('change', function(e) {
        showCompanyLengthRelatedFields(e.target.value);
    });

    initTaxAmountCalculationEvents();
}

module.exports = {
    cleanAndHideTaxAmountSimulationFields,
    disableFields,
    showCompanyLengthRelatedFields,
    getAmountToPayElement,
    initEvents
};

