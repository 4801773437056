const elementUtils = require('../utils/elementUtils');
const sendRecap = require('../api/sendRecap');

const IS_OPENED_CLASS_NAME = 'is-opened';

const RECAP_ELEMENT_ID = 'recap';

const ID_TO_REPLACE_CLASS_NAME = 'id-to-replace';
const PAYMENT_PROMISE_ID_PATTERN = '%id_payment_promise%';

const AMOUNT_TO_REPLACE_CLASS_NAME = 'amount-to-replace';
const PAYMENT_PROMISE_AMOUNT_PATTERN = '%amount%';

const SURVEY_LINK_CLASS_NAME = 'survey-link';
const SURVEY_TOKEN_PATTERN = '%survey_token%';

const SEND_RECAP_CLASS_NAME = 'send-recap';

const BENEFICIARIES_RECAP_CLASS_NAME = 'beneficiaries-recap';

/**
 * Hide recap by element id.
 */
function hideRecap() {
    document.getElementById(RECAP_ELEMENT_ID).classList.remove(IS_OPENED_CLASS_NAME);
}

/**
 * Show recap by element id.
 */
function showRecap() {
    document.getElementById(RECAP_ELEMENT_ID).classList.add(IS_OPENED_CLASS_NAME);
}

/**
 * Replace payment promise ids.
 *
 * @param paymentPromiseIds
 */
function replacePaymentPromiseIds(paymentPromiseIds) {
    const elements = document.getElementsByClassName(ID_TO_REPLACE_CLASS_NAME);

    let paymentPromiseId = paymentPromiseIds[0];
    for(let item of elements) {
        if(item.tagName === 'A') {
            const urlPattern = item.getAttribute('data-url-pattern');
            if(urlPattern) {
                if (paymentPromiseIds.length === 1) {
                    item.href = urlPattern.replace(encodeURIComponent(PAYMENT_PROMISE_ID_PATTERN), paymentPromiseId);
                } else {
                    item.href = urlPattern.replace(encodeURIComponent(PAYMENT_PROMISE_ID_PATTERN), "multi");
                    item.href += "?ids=" +  paymentPromiseIds.join(',');
                }
            }

            const paymentPromiseIdData  = item.getAttribute('data-payment-promise-id');
            if(paymentPromiseIdData) {
                if (paymentPromiseIds.length === 1) {
                    item.setAttribute('data-payment-promise-id', paymentPromiseIdData.replace(PAYMENT_PROMISE_ID_PATTERN, paymentPromiseId));
                } else {
                    item.setAttribute('data-payment-promise-id', paymentPromiseIdData.replace(PAYMENT_PROMISE_ID_PATTERN, paymentPromiseIds));
                    const paymentPromiseModeData  = item.getAttribute('data-payment-promise-mode');
                    if(paymentPromiseModeData) {
                        item.setAttribute('data-payment-promise-mode', "multi");
                    }
                }
            }
        }
    }
}

/**
 * Replace payment promise amount.
 *
 * @param paymentPromiseAmount
 */
function replacePaymentPromiseAmount(paymentPromiseAmount) {
    const elements = document.getElementsByClassName(AMOUNT_TO_REPLACE_CLASS_NAME);

    for(let i in elements) {
        const element = elements[i];

        if(element.tagName === 'SPAN') {
            const innerText = element.innerHTML;
            element.innerHTML = innerText.replace(PAYMENT_PROMISE_AMOUNT_PATTERN, paymentPromiseAmount);
        }
    }
}

/**
 * Open recap.
 */
function openRecap() {
    showRecap();
}

function scrollTo(r) {
    setTimeout(() => {
        document.querySelector('#' + RECAP_ELEMENT_ID + ' + .expandmore__to_expand').scrollIntoView({behavior: "smooth"});
    }, 200);
}

/**
 * Add beneficiaries.
 *
 * @param beneficiaries
 */
function addBeneficiaries(beneficiaries) {
    if(!beneficiaries.length) {
        return;
    }

    const firstElementHtml = '<p class="paragraphe-big">Dont voici le détail :</p>';
    const beneficiaryHtml = '<li class="paragraphe-big"></li>';
    const firstElement = elementUtils.createElementFromHTML(firstElementHtml);

    const wrapper = document.getElementById(RECAP_ELEMENT_ID).nextElementSibling.querySelector('.' + BENEFICIARIES_RECAP_CLASS_NAME);
    wrapper.appendChild(firstElement);

    beneficiaries.forEach(beneficiary => {
        let text = beneficiary.label + ' pour ' + beneficiary.amount + ' euros';
        const element = elementUtils.createElementFromHTML(beneficiaryHtml);
        element.innerText = text;

        wrapper.appendChild(element);
    });
}

/**
 *
 * @param surveyToken
 */
function replacePaymentPromiseSurveyToken(surveyToken) {
    const surveyLinks = document.getElementsByClassName(SURVEY_LINK_CLASS_NAME);
    for(let surveyLink of surveyLinks) {
        const urlPattern = surveyLink.getAttribute('data-url-pattern');
        surveyLink.href = urlPattern.replace(encodeURIComponent(SURVEY_TOKEN_PATTERN), surveyToken);
    }
}

/**
 * Init events.
 */
function initEvents() {
    document.querySelectorAll('.' + SEND_RECAP_CLASS_NAME).forEach(function(element) {
        element.addEventListener('click', function(e) {
            e.preventDefault();

            if(!confirm('Etes-vous sûr de vouloir vous envoyer la promesse de versement par mail ?')) {
                return;
            }

            const paymentPromiseMode = e.target.getAttribute('data-payment-promise-mode');
            const paymentPromiseId = e.target.getAttribute('data-payment-promise-id');

            sendRecap(paymentPromiseMode, paymentPromiseId)
                .then(function(request) {
                    alert('Email envoyé avec succès.');
                    e.target.disabled = true;
                })
                .catch(function(request) {
                    alert('L\'email n\'a pas pu être envoyé.');
                });
        });
    });
}

module.exports = {
    openRecap,
    replacePaymentPromiseAmount,
    replacePaymentPromiseSurveyToken,
    initEvents,
    replacePaymentPromiseIds,
    addBeneficiaries
};